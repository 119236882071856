<template>
    <div> Proje bilgileri yükleniyor lütfen bekleyin</div>
</template>

<script>
export default {
    mounted() {
        setTimeout(() => {
            this.$router.push({ name: 'dashboard'});
        }, 500);

    }
}
</script>